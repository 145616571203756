import { action, computed, decorate, observable, toJS } from "mobx";

class MetaStore {
  _rootStore: any;

  _user: any = {
    username: null,
    permissions: {
      admin: [],
      hotel: {},
    },
    monthlyRateReminder: null,
    zwtToken: null,
  };

  constructor(rootStore: any) {
    this._rootStore = rootStore;
  }

  get user() {
    if (this._user) {
      return toJS(this._user);
    }
  }

  setUser(target: string, value: any) {
    try {
      this._user[target] = value;
    } catch (err) {
      console.error(err);
    }
  }
}

decorate(MetaStore, {
  _user: observable,
  user: computed,
  setUser: action,
});

export { MetaStore };
