import { Component } from "react";
import List from "@mui/material/List";
import InboxIcon from "@mui/icons-material/Inbox";
import PeopleIcon from "@mui/icons-material/People";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LocalHotelIcon from "@mui/icons-material/LocalHotel";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import { Drawer } from "@material-ui/core";
import { Divider, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Outlet } from "react-router-dom";
import withRouter from "../withRouter";
import { connect } from "react-redux";

const userManagementKey = "user";
const execSummarykey = "exec";
const manageRoomsKey = "rooms";
const manageHotelKey = "hotel";
const manageCompSetKey = "CompSet";

const drawerWidth = 269;

const theme: any = createTheme({
  overrides: {
    MuiDrawer: {
      root: {
        position: "relative",
        display: "flex",
        width: drawerWidth,
        flexShrink: 0,
      },
      paper: {
        top: "43px",
        width: drawerWidth,
        zIndex: 0,
      },
    },
    MuiListItem: {
      root: {
        "&$selected": { backgroundColor: "#fce8e6", color: "#d93025" },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: "bolder",
      },
    },
  },
});

interface State {
  currentSelection: string;
  hashChange: any;
  isLoading: boolean;
}

interface Props {
  adminUser: string;
  router: any;
  activeHotel: any;
}

class Settings extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      currentSelection: execSummarykey,
      hashChange: "",
      isLoading: false,
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    const loc = nextProps.router.location.pathname;
    const hotelId = nextProps.activeHotel?.hotelId;

    if (hotelId) {
      const pathToSelection: { [key: string]: string } = {
        [`/${hotelId}/settings/rooms`]: manageRoomsKey,
        [`/${hotelId}/settings/executive-summary`]: execSummarykey,
        [`/${hotelId}/settings/user`]: userManagementKey,
        [`/${hotelId}/settings/hotel-config`]: manageHotelKey,
        [`/${hotelId}/settings/competitive-set`]: manageCompSetKey,
      };

      if (pathToSelection[loc]) {
        return { currentSelection: pathToSelection[loc] };
      }
    }
    return null;
  }

  componentDidMount() {
    const loc = this.props.router.location.pathname;
    const hotelId = this.props.activeHotel?.hotelId;

    if (loc === `/${hotelId}/settings` || loc === `/${hotelId}/settings/`) {
      this.props.router.navigate(`/${hotelId}/settings/executive-summary`);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const hotelId = this.props.activeHotel?.hotelId;
    const loc = this.props.router.location.pathname;

    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      if (loc === `/${hotelId}/settings` || loc === `/${hotelId}/settings/`) {
        this.props.router.navigate(`/${hotelId}/settings/executive-summary`);
      }
    }
  }

  handleClick = (key: string) => {
    const hotelId = this.props.activeHotel?.hotelId;
    this.setState({ currentSelection: key });

    switch (key) {
      case userManagementKey:
        this.props.router.navigate(`/${hotelId}/settings/user`);
        break;
      case execSummarykey:
        this.props.router.navigate(`/${hotelId}/settings/executive-summary`);
        break;
      case manageRoomsKey:
        this.props.router.navigate(`/${hotelId}/settings/rooms`);
        break;
      case manageHotelKey:
        this.props.router.navigate(`/${hotelId}/settings/hotel-config`);
        break;
      case manageCompSetKey:
        this.props.router.navigate(`/${hotelId}/settings/competitive-set`);
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ display: "flex" }}>
          <Drawer variant="permanent">
            <List>
              <ListItem
                button
                selected={this.state.currentSelection === execSummarykey}
                onClick={() => this.handleClick(execSummarykey)}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary="Executive Summary" />
              </ListItem>
              <Divider />
              <ListItem
                button
                selected={this.state.currentSelection === userManagementKey}
                onClick={() => this.handleClick(userManagementKey)}
              >
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItem>
              <Divider />
              <ListItem
                button
                selected={this.state.currentSelection === manageHotelKey}
                onClick={() => this.handleClick(manageHotelKey)}
              >
                <ListItemIcon>
                  <ApartmentIcon />
                </ListItemIcon>
                <ListItemText primary="Hotel Config" />
              </ListItem>
              <Divider />
              <Divider />
              <ListItem
                button
                selected={this.state.currentSelection === manageRoomsKey}
                onClick={() => this.handleClick(manageRoomsKey)}
              >
                <ListItemIcon>
                  <LocalHotelIcon />
                </ListItemIcon>
                <ListItemText primary="Room Config" />
              </ListItem>
              <Divider />
              <ListItem
                button
                selected={this.state.currentSelection === manageCompSetKey}
                onClick={() => this.handleClick(manageCompSetKey)}
              >
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Competitive Set" />
              </ListItem>
            </List>
          </Drawer>
          <div style={{ flexGrow: 1, padding: "20px" }}>
            <Outlet />
          </div>
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    activeHotel: state.hotelData.activeHotel,
  };
};

export default connect(mapStateToProps)(withRouter(Settings));
