import { createTheme } from "@mui/material";
import { baseTheme } from "./base";

export const tableTheme = createTheme(baseTheme, {
  components: {
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "4px",
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "collapse",
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#f5f5f5",
          "& .MuiTableCell-head": {
            color: "rgba(0, 0, 0, 0.87)",
            fontWeight: 600,
            borderBottom: "2px solid rgba(224, 224, 224, 1)",
            fontSize: "14px",
            padding: "16px",
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffffff",
          "& .MuiTableRow-root": {
            "&:nth-of-type(odd)": {
              backgroundColor: "#ffffff",
            },
            "&:nth-of-type(even)": {
              backgroundColor: "#fafafa",
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04) !important",
          },
          "&:last-child td": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "16px",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          fontSize: "14px",
          color: "rgba(0, 0, 0, 0.87)",
        },
      },
    },
  },
});
