import {
  ArrowDownward,
  Search,
  Edit,
  ViewColumn,
  SaveAlt,
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
  Check,
  FilterList,
  Remove,
  Clear,
  AddBox,
  DeleteOutline,
} from "@mui/icons-material";
import React, { forwardRef } from "react";
import MaterialTable, { Icons } from "material-table";
import { any } from "prop-types";
import moment from "moment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import "./matrix.css";

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
export interface HistoryData {
  timestamp: number;
  user: string;
  event: string;
  value: string;
  target: {
    rt: string;
    rp?: string;
  };
}

interface State {
  data: any;
  hotel: any;
  displaySplit: boolean;
  displayNew: boolean;
  splitDate: Date;
  newStartDate: Date;
  newEndDate: Date | null;
  matrixes: any[];
  currentIndex: number;
  minStartNewDate: Date;
}

export default class RateMatrix extends React.Component {
  state: State = {
    matrixes: [],
    data: any,
    splitDate: new Date(),
    newStartDate: new Date(),
    newEndDate: null,
    hotel: any,
    displaySplit: false,
    displayNew: false,
    currentIndex: 0,
    minStartNewDate: new Date(),
  };

  constructor(props: any) {
    super(props);
    this.state.hotel = props.hotel;
    this.formatData = this.formatData.bind(this);
    this.state.matrixes = this.formatData();
    this.preSelectIndex = this.preSelectIndex.bind(this);
    this.state.currentIndex = this.preSelectIndex();

    // @ts-ignore
    this.state.data = this.state.matrixes[0].rates;
    this.exportRateMatrixes = this.exportRateMatrixes.bind(this);
    this.changePeriod = this.changePeriod.bind(this);
    this.toggleSplitControl = this.toggleSplitControl.bind(this);
    this.toggleNewPeriodControl = this.toggleNewPeriodControl.bind(this);
    this.computeDefaultSplitdate = this.computeDefaultSplitdate.bind(this);
    this.setSplitDate = this.setSplitDate.bind(this);
    this.createNewPeriod = this.createNewPeriod.bind(this);
    this.splitPeriod = this.splitPeriod.bind(this);
    this.getNewPeriod = this.getNewPeriod.bind(this);
    this.state.splitDate = this.computeDefaultSplitdate(0);
    this.state.minStartNewDate = moment(
      this.state.matrixes[this.state.matrixes.length - 1].end,
    )
      .add(1, "d")
      .toDate();
    this.state.newStartDate = this.state.minStartNewDate;
  }

  computeDefaultSplitdate(index: number) {
    let start = moment(this.state.matrixes[index].start, "YYYY-MM-DD");
    let end = moment(this.state.matrixes[index].end, "YYYY-MM-DD");
    let diff = end.diff(start, "d") / 2;
    return start.add(diff, "days").toDate();
  }

  componentDidMount() {}
  componentDidUpdate(prevProps: any) {}

  exportRateMatrixes() {
    let res: any[] = [];
    let i = 0;
    for (let matrix of this.state.matrixes) {
      res[i] = {
        start: matrix.start,
        end: matrix.end,
        bands: [],
      };
      for (let item of matrix.rates) {
        res[i].bands[item.rateBand - 1] = {};
        res[i].bands[item.rateBand - 1].formula =
          `[${item.sun},${item.mon},${item.tue},${item.wed},${item.thu},${item.fri},${item.sat}][dow]`;
      }
      i++;
    }

    return res;
  }

  setSplitDate(date: Date) {
    this.setState({ splitDate: date });
  }
  setEndDate(date: Date) {
    this.setState({ newEndDate: date });
  }

  createNewPeriod() {
    let ms = this.state.matrixes;
    let n = this.getNewPeriod(this.state.newStartDate, this.state.newEndDate!);
    let newstartDate = moment(this.state.newEndDate!).add(1, "d").toDate();
    ms.push(n);
    this.setState({
      currentIndex: ms.length - 1, //should be the next index in list necessarly
      matrixes: ms,
      displaySplit: false,
      data: n.rates,
      minStartNewDate: newstartDate,
      newStartDate: newstartDate,
      newEndDate: null,
      displayNew: false,
      //splitDate: this.computeDefaultSplitdate(event.target.value) TODO
    });
  }

  getNewPeriod(start: Date, end: Date) {
    let nPeriod = {
      start: moment(start).format("YYYY-MM-DD"),
      end: moment(end).format("YYYY-MM-DD"),
      rates: new Array(),
    };
    for (let i = 0; i < this.state.data.length; i++) {
      //same number of bands for each period
      nPeriod.rates.push({
        rateBand: i + 1,
        sun: 999,
        mon: 999,
        tue: 999,
        wed: 999,
        thu: 999,
        fri: 999,
        sat: 999,
      });
    }
    return nPeriod;
  }

  splitPeriod() {
    let ms = this.state.matrixes;
    let m = ms[this.state.currentIndex];
    let n = this.getNewPeriod(this.state.splitDate, m.end);
    ms.push(n);
    m.end = this.state.splitDate;
    ms.sort((a: any, b: any) => {
      return moment(a.start).diff(b.start, "d");
    });
    this.setState({
      currentIndex: this.state.currentIndex + 1, //should be the next index in list necessarly
      matrixes: ms,
      displaySplit: false,
      data: n.rates,
      //splitDate: this.computeDefaultSplitdate(event.target.value) TODO
    });
  }

  changePeriod(event: any) {
    this.setState({
      data: this.state.matrixes[event.target.value].rates,
      splitDate: this.computeDefaultSplitdate(event.target.value),
      currentIndex: Number(event.target.value),
    });
  }

  toggleSplitControl(event: any) {
    this.setState({ displaySplit: !this.state.displaySplit });
  }

  toggleNewPeriodControl(event: any) {
    this.setState({ displayNew: !this.state.displayNew });
  }

  preSelectIndex(): number {
    let todayDate = moment(new Date()).format("YYYY-MM-DD");
    for (let i = 0; i < this.state.matrixes.length; i++) {
      let matrix = this.state.matrixes[i];
      let dateEnd = moment(matrix.end);
      let dateStart = moment(matrix.start);
      if (dateEnd.isAfter(todayDate) && dateStart.isBefore(todayDate)) {
        return i;
      }
    }
    return this.state.matrixes.length - 1;
  }

  formatData(): any[] {
    let res: any[] = [];
    let i = 0;
    let date1YearAgo = moment(new Date()).add(-1, "year").format("YYYY-MM-DD");
    for (let matrix of this.state.hotel.suggestionParams.matrixes) {
      let dateEnd = moment(matrix.end);
      if (dateEnd.isAfter(date1YearAgo)) {
        res[i] = {
          start: matrix.start,
          end: matrix.end,
          rates: [],
        };
        matrix.bands.forEach((band: any, index: number, _: []) => {
          let bandsValues = JSON.parse(band.formula.replace("[dow]", ""));
          res[i].rates.push({
            rateBand: index + 1,
            sun: bandsValues[0],
            mon: bandsValues[1],
            tue: bandsValues[2],
            wed: bandsValues[3],
            thu: bandsValues[4],
            fri: bandsValues[5],
            sat: bandsValues[6],
          });
        });
        i++;
      }
    }

    return res;
  }

  render() {
    let data = JSON.parse(JSON.stringify(this.state.data));
    return (
      <React.Fragment>
        <div className="matrixHeader">
          {!this.state.displayNew && (
            <div style={{}}>
              <label htmlFor="property">Period :&nbsp; </label>
              <select
                value={"" + this.state.currentIndex}
                onChange={this.changePeriod}
                className="form-control"
                style={{ width: "300px", display: "inline", height: "60px" }}
              >
                {this.state.matrixes.map((matrix: any, index: number) => {
                  return (
                    <option value={index} key={index}>
                      {moment(matrix.start).format("YYYY-MM-DD")} -{" "}
                      {moment(matrix.end).format("YYYY-MM-DD")}{" "}
                    </option>
                  );
                })}
              </select>
            </div>
          )}
          {!this.state.displaySplit && !this.state.displayNew && (
            <span
              className="btn btn-outline-dark"
              onClick={this.toggleSplitControl}
              style={{
                marginLeft: "30px",
                fontSize: "0.9em",
                paddingTop: "15px",
              }}
            >
              Split period
            </span>
          )}
          {this.state.displaySplit && (
            <div style={{}}>
              <TextField
                defaultValue={
                  this.state.matrixes[this.state.currentIndex].start
                }
                style={{ width: "50%", marginLeft: "50px" }}
                variant="outlined"
                id="outlined-basic"
                fullWidth
                label="Split Date"
                type="date"
                onChange={(event: any) => this.setSplitDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: this.state.matrixes[this.state.currentIndex].start,
                  max: this.state.matrixes[this.state.currentIndex].end,
                }}
              />
              <IconButton aria-label="split" onClick={this.splitPeriod}>
                <Check htmlColor="#4caf50" />
              </IconButton>
              <IconButton
                aria-label="cancel"
                onClick={this.toggleSplitControl}
                color="secondary"
              >
                <Clear />
              </IconButton>
            </div>
          )}
          {!this.state.displaySplit && !this.state.displayNew && (
            <span
              className="btn btn-outline-primary"
              onClick={this.toggleNewPeriodControl}
              style={{
                marginLeft: "30px",
                fontSize: "0.9em",
                paddingTop: "15px",
              }}
            >
              New period
            </span>
          )}
          {this.state.displayNew && (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <TextField
                defaultValue={moment(this.state.newStartDate).format(
                  "YYYY-MM-DD",
                )}
                style={{ width: "50%", marginLeft: "50px" }}
                variant="outlined"
                id="outlined-basic"
                fullWidth
                label="Start"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment(this.state.newStartDate).format("YYYY-MM-DD"),
                  readOnly: true,
                }}
              />
              <TextField
                defaultValue={moment(this.state.newEndDate).format(
                  "YYYY-MM-DD",
                )}
                style={{ width: "50%", marginLeft: "50px" }}
                variant="outlined"
                id="outlined-basic"
                fullWidth
                label="End"
                type="date"
                onChange={(event: any) => this.setEndDate(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: moment(this.state.newStartDate).format("YYYY-MM-DD"),
                }}
              />

              <IconButton
                aria-label="create"
                onClick={this.createNewPeriod}
                color="primary"
              >
                <Check htmlColor="#4caf50" />
              </IconButton>
              <IconButton
                aria-label="cancel"
                onClick={this.toggleNewPeriodControl}
                color="secondary"
              >
                <Clear />
              </IconButton>
            </div>
          )}
        </div>
        <br />
        <div>
          <MaterialTable
            columns={[
              {
                title: "Rate band",
                field: "rateBand",
                editable: "never" as "never",
              },
              {
                title: "Mon",
                field: "mon",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.mon < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Tue",
                field: "tue",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.tue < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Wed",
                field: "wed",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.wed < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Thu",
                field: "thu",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.thu < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Fri",
                field: "fri",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.fri < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Sat",
                field: "sat",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.sat < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
              {
                title: "Sun",
                field: "sun",
                type: "numeric",
                validate: (rowData: any) => {
                  if (rowData.sun < 0) {
                    return "Negative number is not allowed";
                  } else {
                    return "";
                  }
                },
              },
            ]}
            data={data}
            options={{
              search: false,
              // paging: false,
              exportButton: true,
              headerStyle: {
                padding: 5,
                fontWeight: 900,
                fontSize: "0.9em",
                alignContent: "center",
              },
              rowStyle: {
                padding: 5,
              },
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      newData = {
                        rateBand: data.length + 1,
                        ...newData,
                      };
                      data.push(newData);
                      this.setState({ data });
                    }
                    resolve();
                  }, 1000);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise<void>((resolve, reject) => {
                  setTimeout(() => {
                    {
                      const data = this.state.data;
                      let index = 0;
                      data.forEach((item: any, i: number) => {
                        if (oldData.rateBand == oldData.rateBand) {
                          index = i;
                        }
                      });
                      data[index] = newData;
                      this.setState({ data });
                    }
                    resolve();
                  }, 1000);
                }),
              onRowDelete: (oldData) =>
                new Promise<void>((resolve, reject) => {
                  resolve();
                }),
            }}
            title={""}
            icons={tableIcons}
          />
        </div>
      </React.Fragment>
    );
  }
}
