import { Component } from "react";
import { NavLink, Navigate } from "react-router-dom";
import { Props, State } from "../models/navbar.models";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { appStore } from "./store";
import Autocomplete from "@mui/material/Autocomplete";
import ApartmentIcon from "@mui/icons-material/Apartment";
import withRouter from "./withRouter";
import { connect } from "react-redux";
import { setActiveHotel } from "../redux/Slice/hotelSlice";
import { setError } from "../redux/Slice/state";
import { YieldApi } from "api/yield";

let logo: string;
import(`assets/images/${process.env.REACT_APP_STAGE}/navbar-logo.png`).then(
  (module) => {
    logo = module.default;
  },
);

const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "1px solid var(--button-color);",
  boxShadow: 24,
  p: 4,
};

class Navbar extends Component<Props, State> {
  readonly state = {
    dropdownActive: false,
    onboardHotel: false,
    dropDownOpen: false,
    dropdownValue: "",
    inputValue: "",
    counter: 0,
  };

  componentDidMount() {
    window.addEventListener("click", this.closeDropdown);
    this.setState({ counter: this.state.counter + 1 });
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeDropdown);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.dropdownValue !== this.state.dropdownValue) {
      this.setState({ dropDownOpen: false });
    }
  }

  onboardHotel = () => {
    this.setState({ onboardHotel: true });
  };

  handleOnLogout = () => {
    this.props.onLogout();
    setTimeout(() => {
      this.props.router.navigate("/");
    }, 500);
  };

  openHotelDropDown = () => {
    this.setState({ dropDownOpen: true });
  };

  async changeHotel(event: any) {
    if (event) {
      const target = event["id"];
      const loc = this.props.router.location.pathname;
      const parts = loc.split("/");
      const exceptId = parts
        .splice(2, parts.length - 1)
        .join("/")
        .toString();

      this.props.router.navigate(`${target}/${exceptId}`);

      if (this.props.hotels && this.props.hotels.length !== 0) {
        if (target && !isNaN(parseInt(target))) {
          try {
            let h = await YieldApi.getHotelConfig(parseInt(target));
            this.props.setActiveHotel(h);
          } catch (err) {
            console.error(err);
            this.props.setError(true);
            throw err;
          }
        }
      }

      appStore.yieldSheet.outOfRange = new Set();

      this.setState({
        dropdownValue: event["label"],
        dropDownOpen: false,
      });
    }
  }

  render() {
    const dropdownClass = this.state.dropdownActive ? "show" : "";
    const { activeHotel, hotels } = this.props;

    const hotelName =
      hotels?.map((data: any) => {
        return { label: data.name, id: data.hotelId };
      }) || [];

    const currentURL = this.props.router.location.pathname;
    const part = currentURL.split("/");
    const allRoutes = ["yield-sheet", "settings", "executive-summary"];
    const settingsRoute = [
      "hotel-config",
      "comp-set-config",
      "room-config",
      "users",
    ];

    if (part[2] && !allRoutes.includes(part[2])) {
      return <Navigate to={`/${activeHotel?.hotelId}`} />;
    }

    if (part[2] === "settings") {
      if (part[2] !== "settings" && !settingsRoute.includes(part[3])) {
        return <Navigate to={`/${activeHotel?.hotelId}`} />;
      }
    }

    return (
      <nav
        className="navbar navbar-expand navbar-dark bg-dark mb-4"
        id="navbar"
        style={{ position: "fixed", width: "100%", zIndex: "1000" }}
      >
        <NavLink to="" end>
          <img
            className="logo"
            src={logo}
            alt="Revanista Logo"
            id="navbar-revanista-logo"
          />
        </NavLink>
        {currentURL !== "/" &&
          currentURL !== "/onboard-hotel" &&
          activeHotel?.info?.fullName && (
            <span className="hotelName">{" " + activeHotel.info.fullName}</span>
          )}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="revNavBar">
            {currentURL !== "/" &&
              currentURL !== "/onboard-hotel" &&
              activeHotel?.info?.fullName && (
                <>
                  {hotelName.length > 1 && (
                    <li className="hotelDropdown">
                      <Box>
                        <ApartmentIcon
                          className="hotelIcon"
                          onClick={this.openHotelDropDown}
                          aria-label="Select Hotel"
                        />
                        <Modal
                          open={this.state.dropDownOpen}
                          onClose={() => this.setState({ dropDownOpen: false })}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={ModalStyle}>
                            <Autocomplete
                              style={{ width: "100%" }}
                              value={activeHotel?.info?.name || ""}
                              onChange={(event: any, newValue: any) => {
                                if (newValue) {
                                  this.changeHotel(newValue);
                                }
                              }}
                              options={hotelName}
                              getOptionLabel={(option: any) =>
                                typeof option === "string"
                                  ? option
                                  : option.label
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Search Hotel"
                                  variant="outlined"
                                  size="small"
                                />
                              )}
                            />
                          </Box>
                        </Modal>
                      </Box>
                    </li>
                  )}
                  {currentURL === `/${part[1]}` ||
                  (currentURL === `/${part[1]}/` &&
                    !currentURL.includes(`/${part[1]}/settings`)) ? (
                    <li className="nav-item ml-auto">
                      <NavLink
                        to={`${part[1]}/settings/executive-summary`}
                        end
                        className="nav-link"
                      >
                        <span style={{ color: "white" }}>
                          <ion-icon name="settings" />
                          &nbsp;Settings
                        </span>
                      </NavLink>
                    </li>
                  ) : (
                    <li className="nav-item ml-auto">
                      <NavLink to={`${part[1]}`} end className="nav-link">
                        <span style={{ color: "white" }}>
                          <ion-icon name="apps" />
                          &nbsp;Yield Sheet
                        </span>
                      </NavLink>
                    </li>
                  )}
                </>
              )}
            <li className={`nav-item dropdown-bar${dropdownClass}`}>
              <a
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                onClick={this.toggleDropdown}
                style={{ color: "white" }}
              >
                <ion-icon name="contact" />
                {this.props.username}
              </a>
              <div
                className={`dropdown-menu dropdown-menu-right ${dropdownClass}`}
              >
                <a
                  className="dropdown-item"
                  role="button"
                  onClick={this.handleOnLogout}
                >
                  Log out
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }

  private closeDropdown = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).classList.contains("dropdown-toggle")) {
      this.setState({ dropdownActive: false });
    }
  };

  private toggleDropdown = () => {
    this.setState({ dropdownActive: !this.state.dropdownActive });
  };
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
  hotels: state.hotelData.hotels,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveHotel: (hotel: any) => dispatch(setActiveHotel(hotel)),
  setError: (error: boolean) => dispatch(setError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
