import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import React from "react";
import Modal from "react-modal";
import RateMatrix from "./rateMatrix";
import CriteriaMatrix from "./criteriaMatrix";
import { createTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core";
import { YieldApi } from "../../../../api/yield";
import moment from "moment";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: "#ce0e29",
        opacity: 1,
      },
      indicator: {
        backgroundColor: "#ce0e29",
      },
    },

    MuiTab: {
      textColorInherit: {
        opacity: 1,
        fontSize: "0.8em",
        color: "white",
        fontWeight: 700,
      },
      root: {
        backgroundColor: "#ce0e29",
        opacity: 1,
        color: "rgba(255, 255, 255, 1)",
        "&:hover": {
          backgroundColor: "#aa0c22",
        },
        "&$selected": {
          backgroundColor: "#aa0c22",
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: "0.8em",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "1em !important",
      },
    },
  },
});

const style = {
  content: {
    position: "relative" as "relative",
    width: "50%",
    bottom: "50%",
    height: "80%",
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderColor: "rgba(255, 0, 0, 1)",
    inset: "0",
  },
  overlay: {
    position: "fixed" as "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 150,
  },
};

interface State {
  isOpen: boolean;
  isloading: boolean;
  selectedIndex: number;
  value: number;
}
export interface IMatrixModalProps {
  hotel: any;
}

export default class MatrixModal extends React.Component<IMatrixModalProps> {
  hotel: any;

  handleChange = (event: any, newValue: number) => {
    if (this.state.value == 0) {
      this.tempMatrix.criteria =
        this.criteriaMatrixRef.current.exportCriterias();
    } else {
      this.tempMatrix.rateMatrixes =
        this.rateMatrixRef.current.exportRateMatrixes();
    }
    this.setState({ value: newValue });
  };

  state: State = {
    isOpen: true,
    isloading: false,
    selectedIndex: 0,
    value: 0,
  };
  tempMatrix: any = {};
  private criteriaMatrixRef = React.createRef<any>();
  private rateMatrixRef = React.createRef<any>();

  constructor(props: any) {
    super(props);
    this.state.isOpen = false;
    this.hotel = props.hotel;
    //this.changePeriod = this.changePeriod.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
  }

  async open(dayDate: string) {
    this.setState({
      isOpen: true,
      dayDate: dayDate,
      selectedIndex: 0,
    });
  }

  save() {
    if (this.state.value == 0) {
      this.tempMatrix.criteria =
        this.criteriaMatrixRef.current.exportCriterias();
    } else {
      this.tempMatrix.rateMatrixes =
        this.rateMatrixRef.current.exportRateMatrixes();
    }
    let clonedMatrixes = JSON.parse(
      JSON.stringify(this.hotel.suggestionParams.matrixes),
    );
    let res: any[] = [];

    let rateMatrixes = this.tempMatrix.rateMatrixes;
    if (!rateMatrixes) {
      rateMatrixes = clonedMatrixes;
    }
    let i = 0;
    for (let item of rateMatrixes) {
      res[i] = {
        start:
          typeof item.start == "undefined"
            ? moment(new Date()).format("YYYY-MM-DD")
            : item.start,
        end:
          typeof item.end == "undefined"
            ? moment(new Date()).format("YYYY-MM-DD")
            : item.end,
        bands: item.bands,
      };
      i++;
    }
    let criteria = this.tempMatrix.criteria;
    if (criteria) {
      //change in criteria (or not!)
      let i = 0;
      for (let item of rateMatrixes) {
        let j = 0;
        for (let band of criteria) {
          if (res[i].bands[j]) {
            res[i].bands[j].criterias = criteria[j];
          } else {
            if (criteria[j]) {
              //new band
              res[i].bands[j] = {
                criterias: criteria[j],
                formula: "[999,999,999,999,999,999,999][dow]",
              };
            }
          }
          j++;
        }
        let l = res[i].bands.length;
        if (l > criteria.length) {
          let nToRemove = l - criteria.length;
          res[i].bands = res[i].bands.slice(0, nToRemove * -1);
        }
        i++;
      }
    }
    this.setState({ isLoading: true });
    YieldApi.writeSuggestionParams({
      hotelId: this.hotel.hotelId,
      suggestionParams: { matrixes: res },
    })
      .then(() => {
        this.setState({ isLoading: false });
        window.location.reload(); // you can pass true to reload function to ignore the client cache and reload from the server
      })
      .catch((err: any) => {
        this.setState({ isLoading: false });
      });
  }
  close() {
    this.setState({ isOpen: false });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.body.style.overflow = "hidden"; // Apply CSS property when popup is open
      } else {
        document.body.style.overflow = ""; // Reset CSS property when popup is closed
      }
    }
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={() => {
          this.setState({ isOpen: false });
        }}
        contentLabel="Rate Matrix config"
        style={style}
        ariaHideApp={false}
      >
        {" "}
        {this.state.isloading && (
          <Backdrop open={this.state.isloading}>
            <CircularProgress color="inherit" id="circular-progress" />
          </Backdrop>
        )}
        {!this.state.isloading && (
          <MuiThemeProvider theme={theme}>
            <AppBar position="static">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Rate band Definition" {...a11yProps(0)} />
                <Tab label="Rate Matrixes" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <TabPanel value={this.state.value} index={0}>
              <div style={{ padding: 0 }}>
                <div style={{ height: 10 }}></div>
                <CriteriaMatrix
                  ref={this.criteriaMatrixRef}
                  {...{
                    hotel: this.hotel,
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel value={this.state.value} index={1}>
              <div>
                <RateMatrix
                  ref={this.rateMatrixRef}
                  {...{
                    hotel: this.hotel,
                    index: 0,
                  }}
                />
              </div>
            </TabPanel>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "2vh",
              }}
            >
              <div>
                <span
                  className="btn btn-secondary"
                  onClick={this.close}
                  style={{
                    position: "relative",
                    fontSize: "1.1em",
                    width: "min-content",
                  }}
                >
                  Cancel
                </span>
                <span
                  className="btn btn-primary"
                  onClick={this.save}
                  style={{
                    position: "relative",
                    marginLeft: "30px",
                    fontSize: "1.1em",
                    width: "min-content",
                  }}
                >
                  Save
                </span>
              </div>
            </div>
          </MuiThemeProvider>
        )}
      </Modal>
    );
  }
}
