import { computed, decorate, observable, toJS } from "mobx";
import { Hotel } from "./store-types";

import { MetaStore } from "./meta-store";
import { RequestsStore } from "./requests-store";
import { YieldSheetStore } from "./yield-sheet-store";

class AppStore {
  _error: any;
  _meta: any;
  _requests: any;
  _yieldSheet: any;

  constructor() {
    this._error = {};
    this._meta = new MetaStore(this);
    this._requests = new RequestsStore(this);
    this._yieldSheet = new YieldSheetStore(this);
  }

  // ---------------------------------------------------------------------------
  // Error
  // ---------------------------------------------------------------------------
  get error() {
    return this._error;
  }

  // ---------------------------------------------------------------------------
  // Meta
  // ---------------------------------------------------------------------------

  get user() {
    if (this._meta && this._meta.user) {
      return this._meta.user;
    }
    return null;
  }

  reset() {
    this._error = {};
    this._meta = new MetaStore(this);
    this._requests = new RequestsStore(this);
    this._yieldSheet = new YieldSheetStore(this);
  }

  // ---------------------------------------------------------------------------
  // Hotels - TODO: remove
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // Feature store getters
  // ---------------------------------------------------------------------------

  get meta() {
    if (this._meta) {
      return this._meta;
    }
  }

  get requests() {
    try {
      return this._requests;
    } catch (error) {
      console.error(error);
    }
  }

  get yieldSheet() {
    if (this._yieldSheet) {
      return this._yieldSheet;
    }
  }
}

decorate(AppStore, {
  _error: observable,
  error: computed,
  meta: computed,
  user: computed,
  requests: computed,
  _yieldSheet: observable,
  yieldSheet: computed,
});

var appStore = new AppStore();
export { appStore, AppStore };
