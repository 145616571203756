import Amplify, { Auth } from "aws-amplify";

export const config = Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: window.location.hostname,
      path: "/",
      expires: 2, // 2 hours
      secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
  },
  API: {
    endpoints: [
      {
        name: "changelog",
        endpoint: process.env.REACT_APP_CHANGELOG_API_URL,
        custom_header,
      },
      {
        name: "cm",
        endpoint: process.env.REACT_APP_CM_API_URL,
        custom_header,
      },
      {
        name: "user",
        endpoint: process.env.REACT_APP_USER_API_URL,
        custom_header,
      },
      {
        name: "yield",
        endpoint: process.env.REACT_APP_YIELD_API_URL,
        custom_header,
      },
    ],
  },
});

async function custom_header(): Promise<object> {
  return {
    Authorization: await getJwtToken(),
  };
}

async function getJwtToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}
