import { HotTable, HotTableProps } from "@handsontable-pro/react";
import { restrictions, auth, hotel as gnHotel } from "gn-shared";
import React, { Component } from "react";
import { toast } from "react-toastify";
import "./yieldsheet.css";

import {
  IAvailabilityUpdate,
  IRateUpdate,
  IRestrictionsUpdate,
  ISuggestedBaseRateUpdate,
  IManualRateUpdate,
  IRewardsUpdate,
} from "../../models/api.models";
import { YieldApi } from "../../api/yield";
import { CellCoords, IYieldDay } from "../../models/yield.models";
import { rowType, Rowprop } from "./rowProp";
import {
  availabilityExceedsTotalRooms,
  cells,
  columns,
  colWidths,
} from "./yield-sheet.helpers";
import EventTable from "./subcomponents/eventTable/eventTable";
import { getRestrictionsMenuItems } from "./restrictions-menu";

import MatrixModal from "./subcomponents/SuggestionModal/matrixesModal";
import { formatISO, getTime } from "date-fns";

export interface IYieldSheetProps {
  permissions: any;
  hotel: any;
  store: any;
  onDataUpdate: () => void;
  rowProps: Rowprop;
  data: any;
  sheet: IYieldDay[];
  start: Date;
  end: Date;
  compset: any[];
  compareOtaRate: any;
  compareDemand: any;
  activeHotel: any;
}

interface State {
  hotel: any;
  forceRender: boolean;
}

type CellChange = [number, number, number | string, (number | string)?];

class YieldSheet extends Component<IYieldSheetProps, State> {
  state: State = {
    hotel: {},
    forceRender: false,
  };

  private suggestionModalInstanceRef = React.createRef<any>();
  private hotInstanceRef = React.createRef<HotTable>();
  private leftToSellUpdates: any = {};

  constructor(props: any) {
    super(props);
    let hotelObject = JSON.parse(JSON.stringify(this.props.activeHotel));
    this.state.hotel = gnHotel(hotelObject);
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.data === this.props.data) {
      return false;
    }
    return true;
  }

  openSuggestionModal = () => {
    this.suggestionModalInstanceRef.current.open();
  };

  handleEdit = async (changes: CellChange[]) => {
    if (!changes) {
      return;
    }
    const { sheet } = this.props;
    const rateUpdates: IRateUpdate[] = [];
    const suggestedBaseRateUpdates: ISuggestedBaseRateUpdate[] = [];
    const availabilityUpdates: IAvailabilityUpdate[] = [];
    const manualRateUpdates: IManualRateUpdate[] = [];
    const rewardsUpdates: IRewardsUpdate[] = [];
    const rowsProps = this.props.rowProps;

    let columnCount = changes[0];
    console.log("changes===", columnCount);
    const day = sheet[(columnCount[1] as number) - 1];
    const todayDate = formatISO(day.dateMidday, { representation: "date" });

    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: todayDate,
      end: todayDate,
    };

    changes.forEach((change) => {
      const [row, col, prev, current] = change;
      let rp = rowsProps.get(row);
      let rowtype = rp.type;
      if (row === col && row === 0) {
        return;
      }

      if (current === prev) {
        return;
      }

      const day = sheet[(col as number) - 1];
      const isoDate = formatISO(day.dateMidday, { representation: "date" });

      const updateBase = {
        hotelId: this.props.hotel.hotelId,
        start: isoDate,
        end: isoDate,
      };

      const value: number = Number(current);

      let rowProp = rowsProps.get(row);
      if (rowProp && rowProp.type === "ratePlan") {
        manualRateUpdates.push({
          ...updateBase,
          rate: value * 100,
          invTypeCode: rowProp.roomTypeId,
          ratePlanID: rowProp.ratePlanId,
        });
      }

      switch (rowtype) {
        case rowType.suggestedBaseRate:
          console.log("value", value);

          suggestedBaseRateUpdates.push({
            ...updateBase,
            suggestedRate: value,
          });
          break;
        case rowType.actualBaseRate:
          let suggestedBaseRate = sheet[col - 1].suggestedBaseRate;
          // REV-911
          if (suggestedBaseRate === prev) {
            suggestedBaseRateUpdates.push({
              ...updateBase,
              suggestedRate: value,
            });
          }
          rateUpdates.push({
            ...updateBase,
            baseRate: value,
            col: col,
          });
          break;
        case rowType.actualOWSBaseRate:
          rateUpdates.push({
            ...updateBase,
            owsBaseRate: value,
            col: col,
          });
          break;
        case rowType.leftToSellUpdate:
          this.leftToSellUpdates[isoDate!] = value;
          break;
        case rowType.rewards:
          rewardsUpdates.push({
            ...updateBase,
            active: current === "yes" ? true : false,
          });
          break;
      }

      // REV-260
      if (rowtype === rowType.roomType) {
        availabilityUpdates.push({
          ...updateBase,
          availability: value,
          invTypeCode: rp.roomTypeId,
        });
        if (
          availabilityExceedsTotalRooms(this.props.hotel.meta.totalRooms, value)
        ) {
          toast.warn("Availability entered exceeds total number of rooms");
        }
      }
    });

    // REV-260
    if (availabilityUpdates.length) {
      YieldApi.updateAvailability(availabilityUpdates)
        .catch((err: any) => {
          console.error(err);
          toast.error("Error while updating availability");
        })
        .finally(() => {
          this.props.onDataUpdate();
        });
    }

    if (rateUpdates.length && !suggestedBaseRateUpdates.length) {
      await Promise.all(
        rateUpdates.map(async (rateUpdate) => {
          if (columnCount[3] !== "") {
            await YieldApi.bulkUpdateRates(rateUpdate)
              .then(async () => {
                if (
                  columnCount[3] === day.suggestedBaseRate ||
                  columnCount[3] === day.actualBaseRate
                ) {
                  await YieldApi.removeSuggestedDataAfterAcceptOrReject(
                    updateBase,
                  ).catch((err: any) => {
                    console.error(err);
                    toast.error("Error while deleting suggested base rates");
                  });
                }
              })
              .catch((err: any) => {
                console.error(err);
                toast.error("Error while updating rates");
              });
          }
        }),
      );
      this.props.onDataUpdate();
    }

    if (suggestedBaseRateUpdates.length && !rateUpdates.length) {
      if (columnCount[3] !== "") {
        if (Number(columnCount[3]) === day.actualBaseRate) {
          await YieldApi.removeSuggestedDataAfterAcceptOrReject(
            updateBase,
          ).catch((err: any) => {
            console.error(err);
            toast.error("Error while deleting suggested base rates");
          });
        } else {
          await YieldApi.updateSuggestedBaseRate(
            suggestedBaseRateUpdates,
          ).catch((err: any) => {
            console.error(err);
            toast.error("Error while updating suggested base rates");
          });
        }
      }
      this.props.onDataUpdate();
    }

    if (manualRateUpdates.length) {
      YieldApi.updateManualRates(manualRateUpdates)
        .catch((err: any) => {
          console.log(err);
          toast.error("Error while updating manual rate");
        })
        .finally(() => {
          this.props.onDataUpdate();
        });
    }

    if (rewardsUpdates.length) {
      await Promise.all(
        rewardsUpdates.map(async (rewardsUpdate) => {
          await YieldApi.bulkUpdateRewards(rewardsUpdate).catch((err: any) => {
            console.log(err);
            toast.error("Error while updating rewards indicator");
          });
        }),
      );
      this.props.onDataUpdate();
    }
  };

  updateLeftToSell = (payload: any) => {
    YieldApi.updateLeftToSell(payload)
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
      })
      .finally(() => {
        this.props.onDataUpdate();
      });
  };

  updateManualRate = (payload: any) => {
    YieldApi.writeManualRate(payload)
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating manual rate");
      })
      .finally(() => {
        this.props.onDataUpdate();
      });
  };

  updateRestrictions = async (
    { row, col }: CellCoords,
    restrictionKey: RestrictionTypeKeys,
    action: "apply" | "remove",
    numberOfDays?: number,
  ) => {
    const rowProp = this.props.rowProps.get(row);

    const day = this.props.sheet[col - 1];
    const isoDate = formatISO(day.dateMidday, { representation: "date" });
    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: isoDate,
      end: isoDate,
    };
    let restriction: RestrictionToApply = {
      type: restrictions.TYPE[restrictionKey],
      roomType: rowProp!.roomTypeId,
      ratePlan: rowProp!.ratePlanId,
      dayCount: numberOfDays,
    };
    let dayRes = day.restrictions;

    if (action === "apply") {
      await restrictions.apply(day, restriction);
    } else {
      await restrictions.remove(rowProp.globalRes, day, restriction);
    }

    const update: IRestrictionsUpdate = {
      ...updateBase,
      restrictions: dayRes,
    };

    await YieldApi.writeRestrictions(update).catch((err: any) => {
      console.log(err);
      toast.error("Error while updating restrictions");
    });
  };

  bulkUpdateOwsOverride = async (selection: any, label: string) => {
    let start, end;
    if (selection.length === 1) {
      start = selection[0].start;
      end = selection[0].end;
    }
    if (selection.length > 1) {
      start = selection[0].start;
      end = selection[selection.length - 1].end;
    }

    let startCol = start.col;
    let endCol = end.col;
    let dayStart = this.props.sheet[startCol - 1];
    let dayEnd = this.props.sheet[endCol - 1];
    const startIsoDate = formatISO(dayStart.dateMidday, {
      representation: "date",
    });
    const endIsoDate = formatISO(dayEnd.dateMidday, {
      representation: "date",
    });
    let update = {
      hotelId: this.props.hotel.hotelId,
      start: startIsoDate,
      end: endIsoDate,
      label: label,
    };
    YieldApi.bulkUpdateOwsOverride(update)
      .catch((err: any) => {
        console.error(err);
        toast.error("Error while setting OWS override");
      })
      .finally(() => {
        this.props.onDataUpdate();
      });
  };

  closeOrOpenDay = async (
    col: number,
    action: "apply" | "remove",
    subCat: string,
  ) => {
    const resType = "STOP_SELL";
    const rowProps = this.props.rowProps;

    const promises = [];
    for (let row = 0; row <= rowProps.lastRowIndex; row++) {
      const rp = rowProps.get(row);
      const restriction: RestrictionToApply = {
        type: resType,
        roomType: rp.roomTypeId,
        ratePlan: rp.ratePlanId,
      };
      switch (subCat) {
        case "all":
          if (
            typeof restriction.roomType !== "undefined" &&
            typeof restriction.ratePlan === "undefined"
          ) {
            promises.push(
              this.updateRestrictions({ row, col }, resType, action),
            );
          }

          break;
        case "ota":
          if (rp.ratePlanId && rp.modeOfSale === "OTA") {
            promises.push(
              this.updateRestrictions({ row, col }, resType, action),
            );
          }
          break;
        case "ows":
          if (rp.ratePlanId && rp.modeOfSale === "OWS") {
            promises.push(
              this.updateRestrictions({ row, col }, resType, action),
            );
          }
          break;
        case "gds":
          if (rp.ratePlanId && rp.modeOfSale === "GDS") {
            promises.push(
              this.updateRestrictions({ row, col }, resType, action),
            );
          }
          break;
        default:
          break;
      }
    }
    await Promise.all(promises);
    return this.props.onDataUpdate();
  };

  minDayForDay = async (col: number, action: "apply" | "remove") => {
    const resType = "MIN_DAYS";
    const numberOfDays = action === "apply" ? 2 : undefined;
    const rowProps = this.props.rowProps;
    const promises = [];
    for (let row = 0; row <= rowProps.lastRowIndex; row++) {
      let rp = rowProps.get(row);
      const restriction: RestrictionToApply = {
        type: resType,
        roomType: rp.roomTypeId,
        ratePlan: rp.ratePlanId,
      };
      if (
        typeof restriction.roomType !== "undefined" &&
        typeof restriction.ratePlan === "undefined"
      ) {
        promises.push(
          this.updateRestrictions({ row, col }, resType, action, numberOfDays),
        );
      }
    }
    await Promise.all(promises);
    return this.props.onDataUpdate();
  };

  applyLeftToSellLiveInventory = (e: any) => {
    e.stopImmediatePropagation();
    if (Object.keys(this.leftToSellUpdates).length === 0) {
      return;
    }
    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: formatISO(this.props.start, { representation: "date" }),
      end: formatISO(this.props.end, { representation: "date" }),
      updates: this.leftToSellUpdates,
    };
    this.leftToSellUpdates = {};
    YieldApi.updateLeftToSellList(updateBase)
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
      })
      .finally(() => {
        this.props.onDataUpdate();
      });
  };

  computeLeftToSell = (e: any) => {
    e.stopImmediatePropagation();
    if (Object.keys(this.leftToSellUpdates).length === 0) {
      return;
    }

    // Check if any value in leftToSellUpdates is a decimal number
    for (const key in this.leftToSellUpdates) {
      if (this.leftToSellUpdates.hasOwnProperty(key)) {
        const value = Number(this.leftToSellUpdates[key]);
        if (!Number.isInteger(value)) {
          toast("Left to sell cannot be a decimal", { type: "error" });
          return;
        }
      }
    }
    const updateBase = {
      hotelId: this.props.hotel.hotelId,
      start: formatISO(this.props.start, { representation: "date" }),
      end: formatISO(this.props.end, { representation: "date" }),
      updates: this.leftToSellUpdates,
    };
    this.leftToSellUpdates = {};
    YieldApi.updateLeftToSellList(updateBase)
      .catch((err: any) => {
        console.log(err);
        toast.error("Error updating pickup");
      })
      .finally(() => {
        this.props.onDataUpdate();
      });
  };

  getActiveHotel = () => {
    return this.state.hotel;
  };

  render() {
    var { permissions, sheet, store } = this.props;
    (window as any).sheet = sheet;

    permissions.isReadOnly = auth.isReadOnly(
      permissions,
      this.state.hotel.hotelId,
    );
    permissions.canEdit = auth.checkAuthClient(
      permissions,
      this.state.hotel.hotelId,
      auth.editPermission,
    );
    var dates = sheet.map((day) => new Date(day.dateMidday));
    console.log("dates", dates);
    var displayRange =
      sheet.length > 0
        ? {
            start: getTime(sheet[0].hrDate),
            end: getTime(sheet[sheet.length - 1].hrDate),
          }
        : {};
    var self = this;
    console.log("yieldsheet data", sheet, this.props.rowProps.rtConf);

    const hotTableProps: HotTableProps = {
      renderAllRows: true,
      data: this.props.data,
      cells:
        sheet &&
        cells(this.state.hotel, permissions, this.props.rowProps, sheet),
      columns: columns(
        displayRange,
        this.state.hotel,
        store.yieldSheet,
        permissions,
        sheet,
        this.props.compset,
        this.props.compareOtaRate,
        this.props.compareDemand,
        this.props.rowProps,
        this.openSuggestionModal,
        () => {
          this.props.onDataUpdate();
        },
        this.computeLeftToSell,
      ),
      afterChange(changes: CellChange[]) {
        self.handleEdit(changes);
      },

      contextMenu: {
        items: getRestrictionsMenuItems(
          self,
          this.props.rowProps,
          permissions,
          auth.isReadOnly(permissions, this.state.hotel.hotelId),
          this.props.store,
          this.props.activeHotel,
        ),
      },
      beforeOnCellMouseDown: function (e: any) {
        if (
          e.target.textContent.includes("Left to Sell") ||
          e.target.textContent.includes("Apply") ||
          e.target.textContent.includes("Clear")
        ) {
          e.stopImmediatePropagation();
        }
      },

      rowHeaders: false,
      stretchH: "all",
      colWidths: colWidths(sheet.length),
      preventOverflow: true,
      fixedColumnsLeft: 1,
      zIndex: 0,
      rowHeights: 35,
      hiddenRows: {
        rows: this.props.rowProps.hiddenRows,
        indicators: true,
      },

      manualColumnResize: false,
      trimColumns: true,
      autoRowSize: false,
      viewportColumnRenderingOffset: 100,
    } as HotTableProps; // this is needed because nestedRows is experimental and not yet in the official typings
    return (
      <section
        className="fullSection"
        style={{
          overflow: "visible",
          zIndex: 0,
          position: "relative",
          // border: "2px solid black"
        }}
      >
        <div
          style={{
            width: "fit-content",
            zIndex: 1,
            position: "sticky",
            top: 130,
            // marginBottom: "-10000px",
          }}
        >
          <EventTable
            key={sheet[0].dateMidday + sheet[sheet.length - 1].dateMidday}
            {...{
              appStore: this.props.store,
              dates: dates,
              hotel: this.state.hotel,
              ys: this,
            }}
          />
        </div>

        <div
          // Awful hack to hide to overflow
          style={{
            backgroundColor: "white",
            height: 0,
            position: "fixed",
            top: 130,
            width: "100%",
            zIndex: 1,
          }}
        >
          &nbsp;
        </div>
        <div
          style={{
            width: "fit-content",
            zIndex: 0,
            position: "relative",
            bottom: 7,
          }}
        >
          <HotTable ref={this.hotInstanceRef} {...hotTableProps} />
        </div>
        <MatrixModal
          ref={this.suggestionModalInstanceRef}
          hotel={this.state.hotel}
        />
      </section>
    );
  }
}

export { YieldSheet };
