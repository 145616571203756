import { setError } from "redux/Slice/state";
import { store } from "redux/store";
import { setActiveHotel } from "redux/Slice/hotelSlice";
import { YieldApi } from "api/yield";
const revanista = {
  hotel: {
    configure: async (currentUser: any) => {
      const userHotels = store.getState().hotelData.hotels;

      const pathname = window.location.pathname;
      const parts = pathname.split("/");
      const firstRoute = parseInt(parts[1]) || userHotels[0].hotelId;

      console.log("all hotel", firstRoute);
      try {
      } catch (err) {
        console.error(err);
        store.dispatch(setError(true));
        throw err;
      }

      try {
        let h = await YieldApi.getHotelConfig(parseInt(firstRoute));
        store.dispatch(setActiveHotel(h));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(true));
        throw err;
      }
    },
    updateConfig: async (hotelId: number) => {
      try {
        let h = await YieldApi.getHotelConfig(hotelId);
        store.dispatch(setActiveHotel(h));
      } catch (err) {
        console.error(err);
        store.dispatch(setError(true));
        throw err;
      }
    },
  },
};

const ConfigService = {
  revanista: revanista,
};

export { ConfigService };
