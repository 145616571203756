/** Revanista Web Application entry point */

import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import "./styles/index.scss";
import { appStore } from "./App/store";
import { Provider as MobxProvider } from "mobx-react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";

if (process.env.REACT_APP_STAGE === "production") {
  console.log = () => {};
  console.info = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const ROOT = (
  <ReduxProvider store={store}>
    <MobxProvider appStore={appStore}>
      <App store={appStore} />
    </MobxProvider>
  </ReduxProvider>
);
const root = createRoot(document.getElementById("root")!);
root.render(ROOT);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
