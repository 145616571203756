import { configureStore } from "@reduxjs/toolkit";
import hotelSlice from "./Slice/hotelSlice";
import appState from "./Slice/state";

export const store = configureStore({
  reducer: {
    hotelData: hotelSlice,
    appState: appState,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
