// This redux state is for ALL data related to hotels, both active and list of other hotel users have access to.
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface schema {
  activeHotel: Record<string, any>;
  hotels: Array<Record<string, any>>;
}

const initialState = {
  //Currently selected hotel's info and config
  activeHotel: {},
  rowProps: {},
  //The full list of htoels users have access to
  hotels: [],
} as schema;

const hotelSlice = createSlice({
  name: "hotelData",
  initialState,
  reducers: {
    setHotels: (state, action: PayloadAction<Array<Record<string, any>>>) => {
      state.hotels = action.payload;
    },
    setActiveHotel: (state, action: PayloadAction<Record<string, any>>) => {
      state.activeHotel = action.payload;
    },
    resetHotel: () => initialState,
  },
});

export const { setHotels, setActiveHotel, resetHotel } = hotelSlice.actions;

//Will return the full tstae for hotel related data
export const selectAppHotelState = (state: { hotelData: schema }) =>
  state.hotelData;

//Return the list of hotel user have access to.
export const selectHotels = (state: { hotelData: schema }) =>
  selectAppHotelState(state).hotels;

//Return the current selected hotel's info and config.
export const selectActiveHotel = (state: { hotelData: schema }) =>
  selectAppHotelState(state).activeHotel;

export default hotelSlice.reducer;
