// This redux state is for the application state such as error/issues.
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface schema {
  hasError: boolean;
}

const initialState = {
  hasError: false,
} as schema;

const appState = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<boolean>) => {
      state.hasError = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setError, resetState } = appState.actions;

export const selectAppState = (state: { appState: schema }) => state.appState;

export const selectErrorState = (state: { appState: schema }) =>
  selectAppState(state).hasError;

export default appState.reducer;
