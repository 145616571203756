import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import classnames from "classnames";
import { toJS } from "mobx";
import { computeRowProps } from "../yield-sheet/rowProp";
import { Component, Fragment } from "react";
import { toast } from "react-toastify";
import { appStore } from "../store";
import { YieldSheet } from "../yield-sheet/yield-sheet";
import styles from "./dashboard.module.scss";
import DatePicker from "react-datepicker";
import { auth } from "gn-shared";
import { MuiThemeProvider } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import SheetView from "../common/sheetView";

import { IDashboardState, theme } from "./defs";
import { CmApi } from "../../api/cm";
import withRouter from "../withRouter";
import moment from "moment";
import BulkUpdate from "../bulk-update/bulk-update";
import { YieldApi } from "api/yield";
import { connect } from "react-redux";
import { setActiveHotel } from "redux/Slice/hotelSlice";

class Dashboard extends Component<
  {
    router: any;
    activeHotelId: any;
    activeHotel: any;
    setActiveHotel: any;
  },
  IDashboardState
> {
  _isMounted = false;
  readonly state: IDashboardState = {
    dateFrom: appStore.yieldSheet.dateFrom || moment().toDate(),
    dateTo: appStore.yieldSheet.dateTo || moment().endOf("month").toDate(),
    yieldSheetPending: false,
    publishDropdownShown: false,
    selectedHotel: {},
    sheetView: null,
    isEdited: false,
    counter: 0,
    openBulkUpdate: false,
    lastCalendarDate: moment().add(2, "years").format("YYYY-MM-DD"),
  };
  private dropdownBtnRef = React.createRef<HTMLButtonElement>();
  debounceTimeout: NodeJS.Timeout | null = null;

  constructor(props: any) {
    super(props);
    appStore.yieldSheet.outOfRange = new Set();
  }

  async componentDidMount() {
    const { activeHotel } = this.props;
    let hotelData = activeHotel;

    // Check if activeHotel is an empty object
    if (!activeHotel || Object.keys(activeHotel).length === 0) {
      // If empty object in Redux, fetch from API
      const pathname = window.location.pathname;
      const parts = pathname.split("/");
      const hotelId = parseInt(parts[1]);

      if (isNaN(hotelId)) {
        console.error("Invalid hotel ID in URL");
        return;
      }

      try {
        console.log("Fetching hotel data for hotelId:", hotelId);
        hotelData = await YieldApi.getHotelConfig(hotelId);
        console.log("hotel data fetched", hotelData);
        this.props.setActiveHotel(hotelData); // Update Redux store
      } catch (error) {
        console.error("Error loading hotel data:", error);
        return;
      }
    }

    try {
      // Check if hotelData is not empty
      if (hotelData && Object.keys(hotelData).length !== 0) {
        console.log("hotel data being used", hotelData);
        const sheetView = await this.computeSheetviews(hotelData);
        this.setState(
          {
            sheetView,
            selectedHotel: hotelData,
          },
          () => {
            this.refreshViewData();
          },
        );

        // Get calendar date after everything else
        const lastCalendarDate = await YieldApi.getLastCalendarDate(
          hotelData.hotelId,
        );
        if (lastCalendarDate) {
          this.setState({
            lastCalendarDate: lastCalendarDate,
          });
        }
      }
    } catch (error) {
      console.error("Error computing sheet views:", error);
    }
  }

  async computeSheetviews(selectedHotel: any): Promise<SheetView> {
    let rowProp = await computeRowProps(selectedHotel);
    console.log("computed row props", rowProp);
    let s = new SheetView(
      this.props.activeHotel || selectedHotel,
      rowProp,
      moment(this.state.dateFrom).toDate(),
      moment(this.state.dateTo).toDate(),
    );
    return s;
  }

  componentWillMount(): void {
    document.addEventListener("click", (event: any) => {
      if (
        event.target !== this.dropdownBtnRef.current &&
        this.state.publishDropdownShown === true
      ) {
        this.setState({ publishDropdownShown: false });
      }
    });
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.activeHotel !== this.props.activeHotel &&
      !this.state.yieldSheetPending
    ) {
      this.setState({
        selectedHotel: this.props.activeHotel,
        yieldSheetPending: true,
      });

      try {
        if (this.state.sheetView) {
          let sheetView = await this.computeSheetviews(this.props.activeHotel);

          this.setState(
            {
              sheetView: sheetView,
              yieldSheetPending: true,
              selectedHotel: this.props.activeHotel,
            },
            this.refreshViewData,
          );
        }
      } catch (error) {
        console.error("Error refreshing dashboard:", error);
      } finally {
        this.setState({
          yieldSheetPending: false,
          counter: this.state.counter + 1,
        });
      }
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
    document.removeEventListener("click", (event: any) => {
      if (
        event.target !== this.dropdownBtnRef.current &&
        this.state.publishDropdownShown === true
      ) {
        this.setState({ publishDropdownShown: false });
      }
    });
  }

  notifyHotel = () => {
    let notifyPromises: Promise<unknown>[] = [];
    notifyPromises.push(this.state.sheetView.notifyHotel());
    Promise.all(notifyPromises)
      .then((values: any[]) => {
        let c = 0;
        values.forEach((v) => (c += v.count));
        if (c === 0) {
          toast.warn(`no suggested baserate found, no notification sent`);
        } else {
          if (
            typeof this.state.selectedHotel.info.contactEmails != "undefined" &&
            this.state.selectedHotel.info.contactEmails.length > 0
          ) {
            toast.success(`notification sent for ${c} days`);
          } else {
            toast.error(
              "There are no users configured to receive notifications. ",
            );
          }
        }
      })
      .catch((err) => {
        toast.error("Error while notifying hotel");
      });
  };

  async refreshViewData() {
    if (!this.state.sheetView) {
      return;
    }
    // Then refresh the data
    await this.state.sheetView.refreshData();
    this.setState({ yieldSheetPending: false });
    this.setState({ counter: this.state.counter + 1 });
  }

  async changeToToday() {
    await new Promise<void>((resolve) => {
      this.setState(
        {
          dateFrom: moment().toDate(),
          dateTo: moment().endOf("month").toDate(),
          yieldSheetPending: true,
        },
        async () => {
          const newSheetView = await this.computeSheetviews(
            this.state.selectedHotel,
          );
          this.setState(
            {
              sheetView: newSheetView,
              yieldSheetPending: true,
            },
            async () => {
              await this.refreshViewData();
              resolve();
            },
          );
        },
      );
    });
  }

  async changePeriod(daysOffset: number) {
    let currentDate = moment(this.state.dateFrom)
      .add({ months: daysOffset })
      .startOf("month")
      .toDate();

    let selectedDate = moment(this.state.dateFrom)
      .add({ months: daysOffset })
      .startOf("month")
      .toDate();
    let twoYearsFromCurrentDate = moment(currentDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    let goingForward = moment().add(2, "years").format("YYYY-MM-DD");

    if (this.state.selectedHotel && this.state.selectedHotel.hotelOnboardDate) {
      selectedDate =
        currentDate < this.state.selectedHotel.hotelOnboardDate
          ? moment(this.state.selectedHotel.hotelOnboardDate).toDate()
          : currentDate;
    }

    await new Promise<void>((resolve) => {
      this.setState(
        {
          dateFrom: selectedDate,
          dateTo:
            goingForward < twoYearsFromCurrentDate
              ? moment(goingForward).endOf("month").toDate()
              : moment(selectedDate).endOf("month").toDate(),
          yieldSheetPending: true,
        },
        async () => {
          const newSheetView = await this.computeSheetviews(
            this.state.selectedHotel,
          );
          this.setState(
            {
              sheetView: newSheetView,
              yieldSheetPending: true,
            },
            async () => {
              await this.refreshViewData();
              resolve();
            },
          );
        },
      );
    });
  }

  debounce = (func: any, delay: any) => {
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout); // Clear previous timeout
    }
    this.debounceTimeout = setTimeout(() => {
      func.apply(this); // Execute the function after the delay
    }, delay);
  };

  setDateFrom = (date: Date) => {
    let startDate = moment(date);
    let endDate = moment(this.state.dateTo);

    let goingForward = moment().add(2, "years").format("YYYY-MM-DD");

    const monthsDifference = endDate.diff(startDate, "months", true);
    if (monthsDifference > 1) {
      const dateFrom = moment(date).toDate();
      const dateTo = moment(goingForward).isBefore(moment(date).endOf("month"))
        ? moment(goingForward).toDate()
        : moment(dateFrom).add(1, "month").toDate();

      this.setState({ dateFrom, dateTo, yieldSheetPending: true }, async () => {
        const newSheetView = await this.computeSheetviews(
          this.state.selectedHotel,
        );
        this.setState(
          {
            sheetView: newSheetView,
            yieldSheetPending: true,
          },
          async () => {
            await this.refreshViewData();
          },
        );
      });
    } else {
      // Create Moment.js date objects
      const dateFrom = moment(date).toDate();
      const dateTo = moment(goingForward).isBefore(moment(date).endOf("month"))
        ? moment(goingForward).toDate()
        : moment(dateFrom).endOf("month").toDate();

      this.setState({ dateFrom, dateTo, yieldSheetPending: true }, async () => {
        const newSheetView = await this.computeSheetviews(
          this.state.selectedHotel,
        );
        this.setState(
          {
            sheetView: newSheetView,
            yieldSheetPending: true,
          },
          async () => {
            await this.refreshViewData();
          },
        );
      });
    }
  };

  setDateTo = (date: Date | null) => {
    // Create Moment.js date objects
    let startDate = moment(date);
    let endDate = moment(this.state.dateTo);

    // Calculate months difference
    const monthsDifference = endDate.diff(startDate, "months");

    if (monthsDifference > 1) {
      const dateTo = date || new Date();
      const dateFrom = moment(dateTo).subtract(1, "month").toDate();

      this.setState({ dateFrom, dateTo, yieldSheetPending: true }, async () => {
        const newSheetView = await this.computeSheetviews(
          this.state.selectedHotel,
        );
        this.setState(
          {
            sheetView: newSheetView,
            yieldSheetPending: true,
          },
          async () => {
            await this.refreshViewData();
          },
        );
      });
    } else {
      const dateTo = date || new Date();

      this.setState({ dateTo, yieldSheetPending: true }, async () => {
        const newSheetView = await this.computeSheetviews(
          this.state.selectedHotel,
        );
        this.setState(
          {
            sheetView: newSheetView,
            yieldSheetPending: true,
          },
          async () => {
            await this.refreshViewData();
          },
        );
      });
    }
  };

  togglePublishDropdown = () => {
    this.setState({
      publishDropdownShown: !this.state.publishDropdownShown,
    });
  };

  publishAllInView = async () => {
    this.setState({ yieldSheetPending: true });
    let promiseArray = [];
    if (this.state.sheetView.rowProps.isPublishingInventory()) {
      promiseArray.push(this.publishRatesInView(true));
      promiseArray.push(this.publishRestrictionsInView(true));
      promiseArray.push(this.publishAvailabilitiesInView(true));
    } else {
      promiseArray.push(this.publishRatesInView(true));
      promiseArray.push(this.publishRestrictionsInView(true));
    }
    Promise.allSettled(promiseArray).finally(() => {
      this.refreshViewData();
    });
  };

  publishRatesInView = async (publishAll?: boolean) => {
    publishAll = publishAll || false;
    this.setState({ yieldSheetPending: true });
    let toastMessages = {
      pending: "Publishing rates...",
      success: "Published rates",
      error: "Error publishing rates",
    };
    if (process.env.REACT_APP_STAGE !== "production") {
      const isPublishing = await CmApi.publishingHotel(
        this.state.selectedHotel.hotelId,
      );
      if (isPublishing === false) {
        toastMessages = {
          pending: "(f) " + toastMessages.pending,
          success: "(f) " + toastMessages.success,
          error: "(f) " + toastMessages.error,
        };
      }
    }
    await toast.promise(
      YieldApi.publishRatesInView({
        hotelId: this.state.selectedHotel.hotelId,
        start: moment(this.state.dateFrom).format("YYYY-MM-DD")!,
        end: moment(this.state.dateTo).format("YYYY-MM-DD")!,
      }),
      toastMessages,
    );
    if (!publishAll) {
      this.refreshViewData();
    }
  };

  publishAvailabilitiesInView = async (publishAll?: boolean) => {
    publishAll = publishAll || false;
    this.setState({ yieldSheetPending: true });
    let toastMessages = {
      pending: "Publishing availabilities...",
      success: "Published availabilities",
      error: "Error publishing availabilities",
    };
    if (process.env.REACT_APP_STAGE !== "production") {
      const isPublishing = await CmApi.publishingHotel(
        this.state.selectedHotel.hotelId,
      );
      if (isPublishing === false) {
        toastMessages = {
          pending: "(f) " + toastMessages.pending,
          success: "(f) " + toastMessages.success,
          error: "(f) " + toastMessages.error,
        };
      }
    }
    await toast.promise(
      YieldApi.publishAvailabilitiesInView({
        hotelId: this.state.selectedHotel.hotelId,
        start: moment(this.state.dateFrom).format("YYYY-MM-DD")!,
        end: moment(this.state.dateTo).format("YYYY-MM-DD")!,
      }),
      toastMessages,
    );
    if (!publishAll) {
      this.refreshViewData();
    }
  };

  publishRestrictionsInView = async (publishAll?: boolean) => {
    publishAll = publishAll || false;
    this.setState({ yieldSheetPending: true });
    let toastMessages = {
      pending: "Publishing restrictions...",
      success: "Published restrictions",
      error: "Error publishing restrictions",
    };
    if (process.env.REACT_APP_STAGE !== "production") {
      const isPublishing = await CmApi.publishingHotel(
        this.state.selectedHotel.hotelId,
      );
      if (isPublishing === false) {
        toastMessages = {
          pending: "(f) " + toastMessages.pending,
          success: "(f) " + toastMessages.success,
          error: "(f) " + toastMessages.error,
        };
      }
    }
    await toast.promise(
      YieldApi.publishRestrictions({
        hotelId: this.state.selectedHotel.hotelId,
        start: moment(this.state.dateFrom).format("YYYY-MM-DD")!,
        end: moment(this.state.dateTo).format("YYYY-MM-DD")!,
      }),
      toastMessages,
    );
    if (!publishAll) {
      this.refreshViewData();
    }
  };

  getActionList = () => {
    if (!this.state.sheetView || !this.state.sheetView.rowProps) {
      return [];
    }

    let actions = [
      {
        label: "Publish all in view",
        handler: this.publishAllInView,
      },
      {
        label: "Publish rates in view",
        handler: this.publishRatesInView,
      },
      {
        label: "Publish restrictions in view",
        handler: this.publishRestrictionsInView,
      },
    ];

    if (this.state.sheetView.rowProps.isPublishingInventory()) {
      actions.push({
        label: "Publish availabilities in view",
        handler: this.publishAvailabilitiesInView,
      });
    }

    return actions;
  };

  manageBulkUpdatePopUp = (state: boolean) => {
    this.setState({ openBulkUpdate: state });
    this.refreshViewData();
  };

  render() {
    let todayInDateFormat = this.state.dateFrom;
    let activeHotelId = this.state.selectedHotel.hotelId;
    let selectedDate: Date;
    let goingBack;
    let goingForward;
    let lastCalendarDate = this.state.lastCalendarDate;

    if (this.state.selectedHotel && this.state.selectedHotel.hotelOnboardDate) {
      selectedDate =
        todayInDateFormat >
        moment(this.state.selectedHotel.hotelOnboardDate).toDate()
          ? this.state.dateFrom
          : moment(this.state.selectedHotel.hotelOnboardDate).toDate();

      goingBack =
        moment(todayInDateFormat).format("YYYY-MM-DD") <=
        this.state.selectedHotel.hotelOnboardDate
          ? true
          : false;
    } else {
      selectedDate = this.state.dateFrom;
    }

    let endMaxDate =
      moment(selectedDate).add(1, "months").format("YYYY-MM-DD") >
      lastCalendarDate
        ? new Date(lastCalendarDate)
        : new Date(moment(selectedDate).add(1, "months").format("YYYY-MM-DD"));

    goingForward =
      moment(this.state.dateTo).format("YYYY-MM-DD") === lastCalendarDate
        ? true
        : false;
    if (
      this.state.dateTo.getMonth() === new Date(lastCalendarDate).getMonth() &&
      this.state.dateTo.getFullYear() ===
        new Date(lastCalendarDate).getFullYear()
    ) {
      this.state.dateTo = new Date(lastCalendarDate);
    }

    let perms = toJS(appStore.user.permissions);
    perms.canPublish = auth.checkAuthClient(
      perms,
      activeHotelId,
      auth.publishPermission,
    );

    const dropdownClasses = classnames({
      show: this.state.publishDropdownShown,
    });

    return (
      <MuiThemeProvider theme={theme}>
        <div className={styles.container}>
          <div
            className={styles.navigation}
            style={{
              position: "fixed",
              top: "45px",
              width: "100%",
              height: "85px",
              zIndex: "1",
              backgroundColor: "white",
              padding: "10px",
              left: "0px",
            }}
          >
            <nav>
              <button
                className={"icon-button " + styles.double_arrows}
                type="button"
                onClick={() => this.changePeriod(-1)}
                disabled={goingBack}
              >
                <ion-icon name="ios-arrow-back" />
                <span>
                  <ion-icon name="ios-arrow-back" />
                </span>
              </button>
            </nav>
            <span>
              <DatePicker
                portalId="root-portal"
                className="datePicketDashboard"
                id="startDatePicker"
                popperPlacement="bottom-end"
                selected={selectedDate}
                // @ts-ignore
                onChange={this.setDateFrom}
                dateFormat="d LLL yyyy"
                locale="en-GB"
                selectsStart={true}
                startDate={selectedDate}
                endDate={this.state.dateTo}
                minDate={
                  this.state.selectedHotel &&
                  this.state.selectedHotel.hotelOnboardDate &&
                  new Date(this.state.selectedHotel.hotelOnboardDate)
                }
                maxDate={new Date(lastCalendarDate)}
              />
            </span>
            <span>—</span>
            <span>
              <DatePicker
                portalId="root-portal"
                className="datePicketDashboard"
                selected={this.state.dateTo}
                onChange={this.setDateTo}
                id="endDatePicker"
                dateFormat="d LLL yyyy"
                locale="en-GB"
                selectsEnd={true}
                startDate={selectedDate}
                endDate={this.state.dateTo}
                minDate={selectedDate}
                maxDate={endMaxDate}
              />
            </span>
            <nav>
              <button
                className={"icon-button " + styles.double_arrows}
                type="button"
                onClick={() => this.changePeriod(+1)}
                disabled={goingForward}
              >
                <ion-icon name="ios-arrow-forward" />
                <span>
                  <ion-icon name="ios-arrow-forward" />
                </span>
              </button>
            </nav>
            <nav>
              <Tooltip
                title="Reset date range to current month"
                arrow
                placement="top"
              >
                <button
                  className="icon-button"
                  type="button"
                  onClick={() => this.changeToToday()}
                >
                  <ion-icon name="refresh" />
                </button>
              </Tooltip>
            </nav>

            <div
              className={
                perms.canPublish
                  ? `dropdown mr-4 ml-auto ${dropdownClasses}`
                  : "d-none"
              }
            >
              {this.state.yieldSheetPending === true && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  className="mr-4"
                  id="circular-progress"
                />
              )}
              <button
                ref={this.dropdownBtnRef}
                className="btn btn-primary mr-4"
                type="button"
                disabled={this.state.yieldSheetPending}
                onClick={this.notifyHotel}
              >
                Notify Hotel
              </button>

              <button
                ref={this.dropdownBtnRef}
                className="btn btn-primary dropdown-toggle"
                type="button"
                onClick={this.togglePublishDropdown}
                disabled={this.state.yieldSheetPending}
              >
                Publish
              </button>
              <div className={`dropdown-menu ${dropdownClasses}`}>
                {this.getActionList().map((action, index) => (
                  <Fragment key={action.label}>
                    <button
                      className={`dropdown-item ${styles.dropdown_button}`}
                      onClick={() => {
                        action.handler();
                      }}
                    >
                      <b>{action.label}</b>
                    </button>
                    {index !== this.getActionList().length - 1 && (
                      <div className="dropdown-divider" />
                    )}
                  </Fragment>
                ))}
              </div>
            </div>

            <span
              className={perms.canPublish ? "mr-4" : "d-none"}
              style={{ color: "#ce0e29", cursor: "pointer" }}
              onClick={() => this.setState({ openBulkUpdate: true })}
            >
              Bulk Update
            </span>
          </div>
          {!this.state.sheetView ? (
            <Backdrop open={true} invisible={true}>
              <CircularProgress color="inherit" id="circular-progress" />
            </Backdrop>
          ) : this.state.sheetView.days &&
            this.state.sheetView.days.length <= 0 ? (
            <Backdrop open={true} invisible={true}>
              <CircularProgress color="inherit" id="circular-progress" />
            </Backdrop>
          ) : (
            <YieldSheet
              permissions={appStore.user.permissions}
              sheet={this.state.sheetView.days}
              hotel={this.state.sheetView.sheetHotel}
              rowProps={this.state.sheetView.rowProps}
              compset={this.state.sheetView.compset}
              data={this.state.sheetView.sheetData}
              compareOtaRate={this.state.sheetView.compareOtaRate}
              compareDemand={this.state.sheetView.compareDemand}
              onDataUpdate={() => {
                this.setState({ yieldSheetPending: true });
                this.refreshViewData();
              }}
              start={this.state.sheetView.startDate}
              end={this.state.sheetView.endDate}
              store={appStore}
              activeHotel={this.props.activeHotel}
            />
          )}
          {this.state.openBulkUpdate && (
            <BulkUpdate
              isPopupOpen={this.state.openBulkUpdate}
              popupState={this.manageBulkUpdatePopUp}
            />
          )}
        </div>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state: any) => ({
  activeHotel: state.hotelData.activeHotel,
  activeHotelId: state.hotelData.activeHotel?.hotelId,
});

const mapDispatchToProps = (dispatch: any) => ({
  setActiveHotel: (hotel: any) => dispatch(setActiveHotel(hotel)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Dashboard));
