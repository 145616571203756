import { Component, Fragment } from "react";
import { createTheme, MuiThemeProvider } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
const theme: any = createTheme({
  overrides: {
    MuiOutlinedInput: {
      input: {
        padding: "12px",
      },
      root: {
        background: "white",
      },
    },
    MuiFormControl: {
      root: {
        display: "inline-block",
        lineHeight: "50px",
      },
    },
  },
});

interface State {
  editMode: boolean;
  value: string;
  editedValue: string;
}

class SectionTitle extends Component {
  readonly state: State = {
    editMode: false,
    value: "",
    editedValue: "",
  };

  constructor(props: any) {
    super(props);
    this.state.value = props.value;
    this.state.editedValue = props.value;
    this.state.editMode = props.editMode || false;
    this.actions = props.actions;
    this.isEditable = props.isEditable;

    this.onEditRequest = props.onEditRequest
      ? props.onEditRequest
      : this.onEditRequest;
    this.onEditCancel = props.onEditCancel
      ? props.onEditCancel
      : this.onEditCancel;
    this.onChange = props.onChange ? props.onChange : this.onChange;
    this.editSuccess = this.editSuccess.bind(this);
    this.editCancel = this.editCancel.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  isEditable = false;
  value = "";
  actions = [];
  onEditRequest = () => {};
  onChange = (v: string) => {};
  onEditCancel = () => {};

  toggleEdit() {
    if (!this.state.editMode) {
      this.onEditRequest();
    }
    this.setState({ editMode: !this.state.editMode });
  }
  editSuccess() {
    this.onChange(this.state.editedValue);
    this.setState({ value: this.state.editedValue, editMode: false });
  }
  editCancel() {
    this.onEditCancel();
    this.setState({ editedValue: this.state.value, editMode: false });
  }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <div style={{ backgroundColor: "#cc3f53", width: "100%", height: 50 }}>
          <span
            style={{
              fontSize: 18,
              fontWeight: "bold",
              color: "white",
              margin: 30,
              lineHeight: "50px",
            }}
          >
            {!this.state.editMode && this.state.value}
            {this.state.editMode && (
              <FormControl style={{}}>
                <TextField
                  id="outlined-basic"
                  value={this.state.editedValue}
                  variant="outlined"
                  onChange={(e) =>
                    this.setState({ editedValue: e.target.value })
                  }
                />
              </FormControl>
            )}
          </span>
          <span style={{ float: "right", color: "white" }}>
            {this.isEditable && !this.state.editMode && (
              <IconButton aria-label="create" onClick={this.toggleEdit}>
                <EditIcon htmlColor="white" />
              </IconButton>
            )}
            {this.isEditable && this.state.editMode && (
              <Fragment>
                <IconButton aria-label="create" onClick={this.editSuccess}>
                  <CheckIcon htmlColor="white" />
                </IconButton>
                <IconButton aria-label="cancel" onClick={this.editCancel}>
                  <ClearIcon htmlColor="white" />
                </IconButton>
              </Fragment>
            )}
          </span>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default SectionTitle;
